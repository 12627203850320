<template>
  <div class="pt-md-4 pt-0 w-100">
    <router-link
      to="/members"
      class="back-link font-weight-regular"
      :class="isDesktop ? '' : 'black--text white fz-20 py-3 w-100 pl-2 pl-md-0'"
    >
      <v-icon size="24px" color="#6170C5">
        navigate_before
      </v-icon>
      {{isDesktop ? 'Members' : 'Invite members'}}
    </router-link>

    <h1
      class="d-none d-md-block black-text fz-28 mb-4 font-weight-semi-bold"
    >Invite Members</h1>

    <v-card class="pa-2 overflow-hidden" :style="isDesktop ? '' : 'box-shadow:none !important'">
      <v-card-text class="pa-3">
        <v-form v-model="valid" ref="inviteMember">
          <v-row v-for="member in membersList" :key="member.id" style="position: relative">
            <v-col
              cols="11"
              lg="3"
              sm="6"
              class="pb-0 px-1"
            >
              <label class="fz-14 mb-1 d-block required">First name</label>
              <v-text-field
                solo
                outlined
                label="First name"
                type="text"
                v-model="member.firstName"
                :rules="firstNameRules"
                :maxlength="16"
                dense
                @keypress="onlyLettersAndNumbers"
              ></v-text-field>
            </v-col>

            <v-col
              cols="11"
              lg="3"
              sm="6"
              class="pb-0 px-1"
            >
              <label class="fz-14 mb-1 d-block required">Last name</label>
              <v-text-field
                solo
                outlined
                label="Last name"
                type="text"
                v-model="member.lastName"
                :rules="lastNameRules"
                :maxlength="16"
                dense
                @keypress="onlyLettersAndNumbers"
              ></v-text-field>
            </v-col>

            <v-col
              cols="11"
              lg="3"
              sm="6"
              class="pb-0 px-1"
            >
              <label class="fz-14 mb-1 d-block required">Work email</label>
              <v-text-field
                solo
                outlined
                label="Work email"
                type="email"
                v-model="member.email"
                :rules="emailRules"
                dense
                hide-details="auto"
                @keypress="disableSpace"
              ></v-text-field>
            </v-col>
            <v-col
              cols="11"
              lg="3"
              sm="6"
              class="pb-0 px-1"
            >
              <label class="fz-14 mb-1 d-block required">Role</label>
              <v-autocomplete
                solo
                outlined
                v-model="member.value"
                item-value="value"
                item-text="name"
                :items="role"
                label="Role"
                hide-details
                menu-props="offsetY"
                dense
                class="pa-0 fz-14"
              >
                <!-- <template v-slot:item="data">
                  <v-simple-checkbox
                    v-ripple="{ 'center': true }"
                    :value="member.value === data.item.value ? true : false"
                    on-icon="check"
                    indeterminate-icon="remove"
                    off-icon=""
                    @click="member.value = data.item.value"
                  />
                  <span class="pl-2 fz-14 line-h-20">{{ data.item.name }}</span>
                </template> -->
                <template v-slot:append>
                  <v-icon size="15px">unfold_more</v-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="membersList.length > 1"
              :class="{ mobile: !isDesktop }"
              cols="1"
              md="1"
              class="d-flex align-end justify-center close-btn"
            >
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="remove(member.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20px">close</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
              class="px-0"
            >
              <v-divider class="hl-724"></v-divider>
            </v-col>

<!--            <v-col-->
<!--              cols="12"-->
<!--              md="6"-->
<!--              class="pt-1 pb-2"-->
<!--            >-->
<!--              <v-btn-->
<!--                text-->
<!--                class="transparent text-capitalize pa-0"-->
<!--                @click="add"-->
<!--              >-->
<!--                <v-icon-->
<!--                  size="20px"-->
<!--                  class="mr-2"-->
<!--                  color="#007ACE"-->
<!--                >add-->
<!--                </v-icon>-->
<!--                Add members-->
<!--              </v-btn>-->
<!--            </v-col>-->

<!--            <v-col class="d-md-none">-->
<!--              <v-btn-->
<!--                text-->
<!--                class="text-capitalize px-0"-->
<!--                style="opacity: .7"-->
<!--              >-->
<!--                <v-icon size="22">add</v-icon>-->
<!--                Assign mailbox-->
<!--              </v-btn>-->
<!--            </v-col>-->
            <v-col
              cols="12"
              class="d-flex justify-space-between pt-1 pb-2 flex-column flex-md-row px-2"
            >
              <v-btn
                text
                class="transparent text-capitalize pa-0 justify-start mb-5"
                @click="add"
              >
                <v-icon
                  size="20px"
                  class="mr-2"
                  color="#007ACE"
                >add
                </v-icon>
                Add member
              </v-btn>
              <div>
                <v-btn
                  :disabled="disabled"
                  :class="isDesktop ? '' : 'w-100'"
                  :height="isDesktop ? '36px' : '48px'"
                  :width="isDesktop ? '150px' : ''"
                  class="violet-button"
                  @click="invite"
                >
                  Invite member{{ membersList.length > 1 ? 's' : '' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <vue-snotify/>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'invite-members-component',
  data: () => ({
    valid: false,
    membersList: [
      {
        id: Math.random(),
        firstName: '',
        lastName: '',
        email: '',
        value: ''
      }
    ],
    role: [{
      name: 'Company Admin',
      value: 'system_admin'
    }, {
      name: 'Company User',
      value: 'account_user'
    }],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(v) || 'E-mail must be valid'
    ],
    firstNameRules: [
      v => !!v || 'First name is required',
      v => /^[A-Za-z\d]+$/.test(v) || 'Only alphabet or numbers must be used'
    ],
    lastNameRules: [
      v => !!v || 'Last name is required',
      v => /^[A-Za-z\d]+$/.test(v) || 'Only alphabet or numbers must be used'
    ]
  }),
  computed: {
    disabled () {
      return this.membersList.some(e => !e.firstName.trim() || !e.lastName.trim() || !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(e.email) || !e.value)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    remove (id) {
      this.membersList = this.membersList.filter(val => val.id !== id)
    },
    add () {
      if (this.$refs.inviteMember.validate()) {
        this.membersList.push({
          id: Math.random(),
          firstName: '',
          lastName: '',
          email: '',
          value: ''
        })
      }
    },
    invite () {
      if (!this.$refs.inviteMember.validate()) return
      this.membersList.forEach(form =>
        this.$store
          .dispatch('inviteMember', { form: form })
          .then((res) => {
            console.log(res, 'ivite')
            this.$snotify.notifications = []
            this.$snotify.success('Invitation has been sent successfully')
            setTimeout(() => {
              this.$router.push('/members')
            }, 3000)
            // this.$emit('success')
          }).catch(err => {
            this.$snotify.notifications = []
            this.$snotify.error(err.message)
            console.log('err', err)
          }))
    },
    onlyLettersAndNumbers (event) {
      if (!/^[A-Za-z\d]+$/.test(event.key)) return event.preventDefault()
    },
    disableSpace (event) {
      if (event.key === ' ') return event.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
  .close-btn {
    position: absolute;
    right: -33px;
    top: 26px;
    width: 40px;
  }
  .close-btn.mobile {
    top: 50%;
    right: 0;
  }
  ::v-deep .v-input--selection-controls__ripple {
    border-radius: 3px !important;
    height: 16px !important;
    width: 16px !important;
    left: 0px !important;
    top: 0px !important;
    margin: 0 !important;
    border: 1px solid #5C6AC4 !important;
    position: absolute !important;
  }

  ::v-deep .v-input--selection-controls__input {
    width: 16px !important;
    height: 16px !important;
    position: relative !important;

    .v-icon {
      font-size: 14px !important;
      font-weight: 600 !important;
      position: absolute !important;
      top: 1px !important;
      left: 1px !important;
    }
  }
  ::v-deep .v-text-field__details {
    display: none
  }
  ::v-deep .v-label {
    color: #C7C7C7 !important;
  }
</style>
