<template>
  <invite-members-component />
</template>

<script>
import InviteMembersComponent from '@/components/InviteMembersComponent'
export default {
  name: 'InviteMembers',
  components: {
    InviteMembersComponent
  }
}
</script>
